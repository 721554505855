import React, { useRef, useEffect } from "react"

const CookiePolicy = () => {
    const cookiePolicyRef = useRef(null)
    
    useEffect(() => {
        if (cookiePolicyRef.current) {
            const script = document.createElement("script")
            script.src = "https://consent.cookiebot.com/ef27b461-17b5-4ae5-affa-5c1425dc5ae7/cd.js"
            script.async = true
            cookiePolicyRef.current.appendChild(script)
        }
    }, [])
    
    return (
        <section className="container">
            <div className="mb-5">
                <h2 className="font-display text-gold rfs:text-6xl">Cookie Policy</h2>       
                <div className="w-12 mt-5 separator"></div>     
            </div>
            <div ref={cookiePolicyRef}></div>
        </section>
    )
}

export default CookiePolicy